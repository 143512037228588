import Vue from 'vue'
import VueRouter from 'vue-router'
// https://github.com/declandewet/vue-meta
import VueMeta from 'vue-meta'
// Adds a loading bar at the top during page loads.
import NProgress from 'nprogress/nprogress'
import store from '@state/store'
import routes from './routes'
import branding from '@mixins/branding'
import get from 'lodash/get'
import SessionStore from '@state/modules/session'

Vue.mixin(branding)
Vue.use(VueRouter)
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: 'page',
})

const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // mode: 'history',

  // History mode does not play nicely when running the app on a non-root path
  // So we default back to hash mode
  mode: 'hash',

  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0,
      }
    }
  },
})

router.afterEach((_to, _from) => {
  let flashMessage = store.getters['globals/flash']
  if (flashMessage) {
    router.app.$snotify.success(flashMessage)
    router.app.$store.commit('globals/resetFlash')
  }
})

// Before each route evaluates...
router.beforeEach((_routeTo, routeFrom, next) => {
  if (routeFrom.name) {
    store.commit('globals/loading', true)
    // Start the route progress bar.
    NProgress.configure({
      showSpinner: false,
    }).start()
  }
  router.authenticate(next)
})

router.authenticate = async next => {
  const initialized = store.getters['auth/loggedIn'] && SessionStore.initialized
  if (!initialized) {
    // Three awaits in a row is not ideal
    // The old and new store modules cannot fetch at the same time
    // And the LD init is very fast
    const [_hash, query] = window.location.hash.split('#')[1].split('?')
    const params = Object.fromEntries(new URLSearchParams(query))
    window.localStorage.setItem('adminLocale', params.lang)
    await store.dispatch('auth/validate')
    await SessionStore.fetch()
    await Vue.prototype.$launchDarkly.initialize()
    await mixpanelIdentifyUser()
  }

  const returnUrl = window.localStorage.getItem('returnAfterAuth')
  if (returnUrl) {
    window.localStorage.removeItem('returnAfterAuth')
    window.location.href = returnUrl
  } else {
    next()
  }
}

async function mixpanelIdentifyUser() {
  const userId = get(SessionStore.currentUser, 'id')
  const userEmail = get(SessionStore.currentUser, 'email')
  const site = window.location.host
  const analytics = Vue.prototype.$analytics
  if (userId && analytics) {
    await analytics.initialize()
    analytics.identify(`${site}:${userId}`)
    analytics.people(userEmail)
  }
}

// After navigation is confirmed, but before resolving...
router.beforeResolve((_routeTo, _routeFrom, next) => {
  next()
})

// When each route is finished evaluating...
router.afterEach((_routeTo, _routeFrom) => {
  NProgress.done() // Stop loading bar
  store.commit('globals/loading', false)
})

export default router
