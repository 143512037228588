































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import KrBadgesBanner from './kr-badges-banner.vue'
import NoKrsAssigned from './no-krs-assigned-empty-state.vue'
import AllKrsCompleted from './all-krs-completed-empty-state.vue'
import KnowledgeRefresherQuiz from './knowledge-refresher-quiz.vue'
import KnowledgeRefresher from './knowledge-refresher.vue'
import EnrollmentStore from '@state/modules/enrollments'
import { EnrollmentStatuses } from '@/src/models/enrollment'
import OptionalEnrollment from '@/src/models/optional-enrollment'
import BadgesStore from '@src/state/modules/badges'

@Component({
  components: {
    KrBadgesBanner,
    NoKrsAssigned,
    AllKrsCompleted,
    KnowledgeRefresher,
    KnowledgeRefresherQuiz,
  },
})
export default class TrainingNavigation extends Vue {
  showModal: boolean = false
  selectedRefresher = {} as OptionalEnrollment
  counter = 0

  async mounted() {
    await EnrollmentStore.fetchEnrollments()
  }

  get knowledgeRefreshers() {
    return EnrollmentStore.knowledgeRefresherEnrollments.filter(
      e => e.state !== 'completed'
    ) as OptionalEnrollment[]
  }

  get completedKnowledgeRefreshers() {
    return EnrollmentStore.knowledgeRefresherEnrollments.filter(
      e => e.state === 'completed'
    ) as OptionalEnrollment[]
  }

  closeKr() {
    this.showModal = false
    EnrollmentStore.refreshEnrollment(this.selectedRefresher.id)
    BadgesStore.earnBadges(this.selectedRefresher.id)
    this.selectedRefresher = {} as OptionalEnrollment
  }

  openKrModal(refresher) {
    this.selectedRefresher = refresher
    this.showModal = true
  }
}
